$(document).ready(function() {
    
    typeof $.typeahead === 'function' && $("#main-search-typeahead").typeahead({
        input: '#main-search-typeahead .js-typeahead',
        minLength: 1,
        order: "asc",
        dynamic: true,
        delay: 320,
        href: function (item) {
            if(item.data_type === 'Title') {
                return '/title/' + item.id
            } else {
                return '/person/' + item.id
            }
        },
        searchOnFocus: true,
        maxItem: 8,
        maxItemPerGroup: 6,
        template: function (query, item) {
            var color = "#777";
            
            if(item.data_type === 'Title') {
                return '<span class="row g-0">' + '<span class="col">' +
                            '<img class="float-start" src="//image.tmdb.org/t/p/w45{{image_path}}" onError="this.src = \'/images/no-poster-w45.png\'" alt="">' +
                            '<p class="ps-1 overflow-hidden">{{name}}' + (item.year ? '<small>({{year}})</small>' : '<small></small>') + '</p>' +
                        '</span>' + '</span>'
            } else {
                return '<span class="row g-0">' + '<span class="col">' +
                            '<img class="float-start" src="//image.tmdb.org/t/p/w45{{image_path}}" onError="this.src = \'/images/no-profile-w45.png\'" alt="">' +
                            '<p class="ps-1 overflow-hidden">{{name}}</p>' +
                        '</span>' + '</span>'
            }
            
        },
        emptyTemplate: "<p class='overflow-hidden'>No results found for {{query}}</p>",
        source: {
            item: {
                display: "name",
                ajax: function (query) {
                    return {
                        type: "GET",
                        url: "/autocomplete",
                        data: {
                            q: "{{query}}"
                        },
                        callback: {
                            done: function (data) {
                                // console.log(data);
                            }
                        }
                    }
                }
            }
        },
        callback: {
            onShowLayout: function (node, query) {
            },
            onClick: function (node, a, item, event) {
     
                // You can do a simple window.location of the item.href
                // alert(JSON.stringify(item));
     
            },
            onSendRequest: function (node, query) {

            },
            onReceiveRequest: function (node, query) {

            }
        },
        debug: true
    });

    $(".selectable").hover(
       function(){ $(this).addClass('darken') },
       function(){ $(this).removeClass('darken') }
    )

    $('ul.nav li.dropdown').hover(function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(300);
    }, function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(300);
    });
});